import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HeaderSideMenu } from './side-menu'
import { FloatingMenu } from './floating-menu'
import { ExchangeMenu } from './exchange-menu'
import { useAppDispatch, useAppNavigate, useAppSelector, useAppState } from '../../hook'
import { RootState } from '../../store'
import { Menu } from '../../enum'
import { LanguageMenu } from './language-menu/language-menu'
import { getDocuments } from '../../action/document'
import './header.scss'

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
    const dispatch = useAppDispatch()
    const navigate = useAppNavigate()
    const pathname = window.location.pathname

    const { t, i18n } = useTranslation()
    const [state, setState] = useAppState({
        breadcrumbTitle: t('header_menu_home_button'),
        breadcrumbSubTitle: '',
        headerTitle: t('home_header_title'),
        headerDescription: t('home_header_description'),
        needSmallMargin: false,
        exchangeMenuVisible: false,
        floatingMenuVisible: false,
        languageMenuVisible: false,
        menuVisible: false,
        language: i18n.language,
    })

    const menu = useAppSelector((state: RootState) => state.base.menu)

    const onChangeLanguageClick = async (lng: string) => {
        setState((prevState) => ({ ...prevState, language: lng }))
        i18n.changeLanguage(lng)

        if (pathname === '/alapok/konzervativ') {
            await getDocuments(1, lng)
        }

        if (pathname === '/alapok/kiegyensulyozott') {
            await getDocuments(2, lng)
        }

        if (pathname === '/alapok/dinamikus') {
            await getDocuments(3, lng)
        }
    }

    const onHandleLanugageMenuVisible = () => {
        setState((prevState) => ({ ...prevState, languageMenuVisible: !state.languageMenuVisible }))
    }

    const handleExchangeMenuVisible = () => {
        setState((prevState) => ({ ...prevState, exchangeMenuVisible: !state.exchangeMenuVisible }))
    }

    const handleFloatingMenuVisible = () => {
        setState((prevState) => ({ ...prevState, floatingMenuVisible: !state.floatingMenuVisible }))
    }

    const handleMenuVisible = () => {
        setState((prevState) => ({ ...prevState, menuVisible: !state.menuVisible }))
    }

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    useEffect(() => {
        setState((prevState) => ({ ...prevState, needSmallMargin: false }))
        switch (menu) {
            case Menu.Home:
                setState((prevState) => ({ ...prevState, headerTitle: t('home_header_title'), headerDescription: t('home_header_description'), breadcrumbTitle: t('header_menu_home_button'), breadcrumbSubTitle: '' }))
                break
            case Menu.Founds:
                setState((prevState) => ({ ...prevState, headerTitle: t('home_header_title'), headerDescription: t('home_header_description'), breadcrumbTitle: t('header_menu_founds_button'), breadcrumbSubTitle: '', needSmallMargin: true }))
                break
            case Menu.FoundsConservative:
                setState((prevState) => ({ ...prevState, headerTitle: t('founds_conservative_title'), headerDescription: t('founds_conservative_description'), breadcrumbTitle: t('header_menu_founds_button'), breadcrumbSubTitle: t('founds_conservative_breadcrumb_title') }))
                break
            case Menu.FoundsBalanced:
                setState((prevState) => ({ ...prevState, headerTitle: t('founds_balanced_title'), headerDescription: t('founds_balanced_description'), breadcrumbTitle: t('header_menu_founds_button'), breadcrumbSubTitle: t('founds_balanced_breadcrumb_title') }))
                break
            case Menu.FoundsDynamic:
                setState((prevState) => ({ ...prevState, headerTitle: t('founds_dynamic_title'), headerDescription: t('founds_dynamic_description'), breadcrumbTitle: t('header_menu_founds_button'), breadcrumbSubTitle: t('founds_dynamic_breadcrumb_title') }))
                break
            case Menu.Managers:
                setState((prevState) => ({ ...prevState, headerTitle: t('managers_header_title'), headerDescription: t('managers_header_description'), breadcrumbTitle: t('header_menu_managers_button'), breadcrumbSubTitle: '' }))
                break
            case Menu.Company:
                setState((prevState) => ({ ...prevState, headerTitle: t('company_header_title'), headerDescription: t('company_header_description'), breadcrumbTitle: t('header_menu_impressum_button'), breadcrumbSubTitle: '' }))
                break
            case Menu.Contacts:
                setState((prevState) => ({ ...prevState, headerTitle: t('contacts_header_title'), headerDescription: t('contacts_header_description'), breadcrumbTitle: t('header_menu_contacts_button'), breadcrumbSubTitle: '' }))
                break
            case Menu.Cookies:
                setState((prevState) => ({ ...prevState, headerTitle: t('cookie_header_title'), headerDescription: t(''), breadcrumbTitle: t('footer_cookie_button'), breadcrumbSubTitle: '', needSmallMargin: true }))
                break
            case Menu.Impressum:
                setState((prevState) => ({ ...prevState, headerTitle: t('impressum_header_title'), headerDescription: t(''), breadcrumbTitle: t('footer_impressum_button'), breadcrumbSubTitle: '', needSmallMargin: true }))
                break
            case Menu.Terns:
                setState((prevState) => ({ ...prevState, headerTitle: t('footer_terms_button'), headerDescription: t(''), breadcrumbTitle: t('footer_terms_button'), breadcrumbSubTitle: '', needSmallMargin: true }))
                break
            default:
                setState((prevState) => ({ ...prevState, headerTitle: t('home_header_title'), headerDescription: t('home_header_description'), breadcrumbTitle: t('header_menu_home_button'), breadcrumbSubTitle: '' }))
                break
        }

        switch (window.location.pathname) {
            case '/':
                onMenuItemClick(Menu.Home)
                break
            case '/alapok':
                onMenuItemClick(Menu.Founds)
                break
            case '/alapok/konzervativ':
                onMenuItemClick(Menu.FoundsConservative)
                break
            case '/alapok/kiegyensulyozott':
                onMenuItemClick(Menu.FoundsBalanced)
                break
            case '/alapok/dinamikus':
                onMenuItemClick(Menu.FoundsDynamic)
                break
            case '/menedzserek':
                onMenuItemClick(Menu.Managers)
                break
            case '/cegunk':
                onMenuItemClick(Menu.Company)
                break
            case '/kapcsolat':
                onMenuItemClick(Menu.Contacts)
                break
            case '/sutik':
                onMenuItemClick(Menu.Cookies)
                break
            case '/impresszum':
                onMenuItemClick(Menu.Impressum)
                break
            case '/joginyiltakozat':
                onMenuItemClick(Menu.Terns)
                break
        }
    }, [menu, setState, t, window.location.pathname])

    return (
        <header className={`header ${Menu.Home === menu ? `header-home-background` : ``}`}>
            <div className="header-parent-layer">
                <div className="header-bg-layer">
                    {/* <img alt="Főoldal háttér" className={`home-background ${Menu.Home === menu ? `` : `hidden`}`} src="/image/home_background.png" /> */}

                    <div className="video-layer">
                        <video playsInline webkit-playsinline className={`home-background ${Menu.Home === menu ? `` : `hidden`}`} width="1920" height="600" autoPlay loop muted>
                            {/* <!-- MP4 for Safari, IE9, iPhone, iPad, Android, and Windows Phone 7 --> */}
                            <source src="/anim/otp_alap_hero_anim_1920x600.mp4" type="video/mp4" />
                            {/* <!-- WebM/VP8 for Firefox4, Opera, and Chrome--> */}
                            <source src="/anim/otp_alap_hero_anim_1920x600.webm" type="video/webm" />
                            {/* <!-- M4V for Apple--> */}
                            <source src="/anim/otp_alap_hero_anim_1920x600.m4v" type="video/mp4" />
                            {/* <!-- Ogg/Vorbis for older Firefox and Opera versions--> */}
                            <source src="/anim/otp_alap_hero_anim_1920x600.ogv" type="video/ogg" />
                        </video>
                    </div>
                    <img alt="Főoldal háttér" className={`home-background-mobile ${Menu.Home === menu ? `` : `hidden`}`} src="/image/home_background_mobile.png" />

                    <img alt="OTP logo" className={`logo-crop ${Menu.Cookies === menu || Menu.Terns === menu || Menu.Impressum === menu ? `hidden` : ``}`} src="/image/otp_logo_crop.svg" />

                    <img alt="Főoldal háttér" className={`logo-crop-bottom  ${Menu.Cookies === menu || Menu.Terns === menu || Menu.Impressum === menu ? `` : `hidden`}`} src="/image/otp_logo_crop_bottom.svg" />
                </div>

                <div className="header-front-layer">
                    <div className="container">
                        <div className="top-bar">
                            <Link to="/" onClick={() => onMenuItemClick(Menu.Home)}>
                                {state.language.includes('hu') ? <img alt="OTP logo crop" className="logo" src="/image/otp_alapkezelo_white_logo.svg" /> : <img alt="OTP logo crop" className="logo-en" src="/image/otp_alapkezelo_white_logo_en.svg" />}
                            </Link>
                            <div className="exchange-language-container">
                                <div className="exchange">
                                    <button className="exchange-button" onClick={handleExchangeMenuVisible}>
                                        {t('header_exchange_button')}
                                    </button>

                                    <div className="divider"></div>

                                    <button className="arrow-button" onClick={handleExchangeMenuVisible}>
                                        <img className={`arrow-icon ${state.exchangeMenuVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down.svg" />
                                    </button>
                                </div>

                                <a className="language" onMouseEnter={onHandleLanugageMenuVisible} onMouseLeave={onHandleLanugageMenuVisible}>
                                    {`${
                                        state.language.includes('hu')
                                            ? t('header_langugage_hu_button')
                                            : state.language.includes('hr')
                                            ? t('header_langugage_hr_button')
                                            : state.language.includes('mne')
                                            ? t('header_langugage_mne_button')
                                            : state.language.includes('sl')
                                            ? t('header_langugage_sl_button')
                                            : state.language.includes('bg')
                                            ? t('header_langugage_bg_button')
                                            : t('header_langugage_en_button')
                                    }`}

                                    <LanguageMenu open={state.languageMenuVisible} onItemClick={onChangeLanguageClick} />
                                </a>
                            </div>

                            <div className="language-menu-container">
                                <a className="language" onClick={() => onChangeLanguageClick(state.language.includes('hu') ? 'en' : 'hu')}>
                                    {`${state.language.includes('hu') ? t('header_langugage_en_short_button') : t('header_langugage_hu_short_button')}`}
                                </a>

                                <button className="menu-button" onClick={handleMenuVisible}>
                                    <img alt="Menu ikon" src="/image/ic_menu.svg" />
                                </button>
                            </div>
                        </div>

                        <ul className="menu-bar">
                            <li className="menu-bar-item">
                                <Link className={`title left ${Menu.Home === menu ? `active` : ``}`} to={'/'} onClick={() => onMenuItemClick(Menu.Home)}>
                                    {t('header_menu_home_button')}
                                </Link>
                            </li>

                            <li className="menu-bar-item">
                                <Link className={`title  left dropbtn ${Menu.Founds === menu || Menu.FoundsBalanced === menu || Menu.FoundsConservative === menu || Menu.FoundsDynamic === menu ? `active` : ``}`} to={'alapok'} onClick={() => onMenuItemClick(Menu.Founds)} onMouseEnter={handleFloatingMenuVisible} onMouseLeave={handleFloatingMenuVisible}>
                                    {t('header_menu_founds_button')}
                                </Link>

                                <FloatingMenu open={state.floatingMenuVisible} menu={menu} onMenuItemClick={onMenuItemClick} />
                            </li>

                            <li className="menu-bar-item">
                                <Link className={`title ${Menu.Managers === menu ? `active` : ``}`} to={'menedzserek'} onClick={() => onMenuItemClick(Menu.Managers)}>
                                    {t('header_menu_managers_button')}
                                </Link>
                            </li>

                            <li className="menu-bar-item">
                                <Link className={`title right ${Menu.Company === menu ? `active` : ``}`} to={'cegunk'} onClick={() => onMenuItemClick(Menu.Company)}>
                                    {t('header_menu_impressum_button')}
                                </Link>
                            </li>

                            <li className="menu-bar-item">
                                <Link className={`title right ${Menu.Contacts === menu ? `active` : ``}`} to={'kapcsolat'} onClick={() => onMenuItemClick(Menu.Contacts)}>
                                    {t('header_menu_contacts_button')}
                                </Link>
                            </li>
                        </ul>

                        <div className="breadcrumb">
                            <Link to="/" onClick={() => onMenuItemClick(Menu.Home)}>
                                <img alt="Főoldal logója" className="home" src="/image/ic_home.svg" />
                            </Link>

                            <img alt="Nyíl icon" className="arrow" src="/image/ic_arrow_right.svg" />

                            <a
                                className={`page-title  ${state.breadcrumbSubTitle.length != 0 ? `active` : ``}`}
                                onClick={() => {
                                    if (state.breadcrumbSubTitle.length != 0) {
                                        onMenuItemClick(Menu.Founds)
                                        navigate('/alapok')
                                    }
                                }}
                            >
                                {state.breadcrumbTitle}
                            </a>

                            <img alt="Nyíl icon" className="arrow" src="/image/ic_arrow_right.svg" style={{ display: state.breadcrumbSubTitle.length === 0 ? 'none' : '' }} />

                            <a className="page-title sub-title" style={{ display: state.breadcrumbSubTitle.length === 0 ? 'none' : '' }}>
                                {state.breadcrumbSubTitle}
                            </a>
                        </div>

                        <div className={`text-container ${state.needSmallMargin ? `small-margin` : ``} ${i18n.language.includes('bg') ? `bulgarian` : ``}`}>
                            <h1 className="header-text">{state.headerTitle}</h1>

                            <p className={`label-text ${state.headerDescription === '' ? `small-margin` : ``}`}>{state.headerDescription}</p>
                        </div>
                    </div>

                    <HeaderSideMenu open={state.menuVisible} language={state.language} menu={menu} onMenuItemClick={onMenuItemClick} onCloseButtonClick={handleMenuVisible} onChangeLanguageClick={onChangeLanguageClick} />

                    <ExchangeMenu open={state.exchangeMenuVisible} />
                </div>
            </div>
        </header>
    )
}
