import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppState, useAppTranslation, useWindowDimensions, useAppDispatch } from '../../../hook'
import { FooterCardContent } from './../mobile-footer-card-constant'
import { Menu } from '../../../enum'
import { MobileModal } from '../../modal'
import './footer.card.scss'

export const FooterCard = () => {
    const dispatch = useAppDispatch()
    const { t } = useAppTranslation()
    const pathname = window.location.pathname
    const { width } = useWindowDimensions()
    const [state, setState] = useAppState({
        title: '',
        description: '',
        path: '',
        buttonText: '',
        modalVisible: false,
        isMobile: false,
    })

    const onCloseClick = () => {
        setState((prevState) => ({ ...prevState, modalVisible: false }))
    }

    const handleOpenModal = (id: number) => {
        const contentDecider = FooterCardContent.filter((cont) => cont.id === id)[0]

        if (contentDecider.path === '/alapok/konzervativ') {
            onMenuItemClick(Menu.FoundsConservative)
        }

        if (contentDecider.path === '/alapok/kiegyensulyozott') {
            onMenuItemClick(Menu.FoundsBalanced)
        }

        if (contentDecider.path === '/alapok/dinamikus') {
            onMenuItemClick(Menu.FoundsDynamic)
        }

        setState((prevState) => ({ ...prevState, modalVisible: true, title: t(contentDecider.title), description: t(contentDecider.description), path: contentDecider.path, buttonText: t(contentDecider.buttonText) }))
    }

    useEffect(() => {
        setState((prevState) => ({ ...prevState, isMobile: false }))
        if (width <= 576) {
            setState((prevState) => ({ ...prevState, isMobile: true }))
        }
    }, [width])

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    return (
        <div className="footer-card-container">
            <Link
                className={`footer-card-button first ${state.isMobile && pathname === '/alapok/konzervativ' ? 'hidden' : pathname === '/alapok/konzervativ' ? 'disabled' : ''}`}
                to={state.isMobile ? '' : '/alapok/konzervativ'}
                onClick={() => {
                    if (!state.isMobile) {
                        window.scrollTo({ top: 0, left: 0 })
                        onMenuItemClick(Menu.FoundsConservative)
                    } else {
                        handleOpenModal(1)
                    }
                }}
            >
                <span className="footer-link">{t('footer_card_first_card_title')}</span>
            </Link>

            <Link
                className={`footer-card-button second ${state.isMobile && pathname === '/alapok/kiegyensulyozott' ? 'hidden' : pathname === '/alapok/kiegyensulyozott' ? 'disabled' : ''}`}
                to={state.isMobile ? '' : '/alapok/kiegyensulyozott'}
                onClick={() => {
                    if (!state.isMobile) {
                        window.scrollTo({ top: 0, left: 0 })
                        onMenuItemClick(Menu.FoundsBalanced)
                    } else {
                        handleOpenModal(2)
                    }
                }}
            >
                <span className="footer-link">{t('footer_card_second_card_title')}</span>
            </Link>

            <Link
                className={`footer-card-button third ${state.isMobile && pathname === '/alapok/dinamikus' ? 'hidden' : pathname === '/alapok/dinamikus' ? 'disabled' : ''}`}
                to={state.isMobile ? '' : '/alapok/dinamikus'}
                onClick={() => {
                    if (!state.isMobile) {
                        window.scrollTo({ top: 0, left: 0 })
                        onMenuItemClick(Menu.FoundsDynamic)
                    } else {
                        handleOpenModal(3)
                    }
                }}
            >
                <span className="footer-link">{t('footer_card_third_card_title')}</span>
            </Link>

            <MobileModal visible={state.modalVisible} title={state.title} description={state.description} path={state.path} onCloseClick={onCloseClick} buttonText={state.buttonText} />
        </div>
    )
}
