import { FooterCard, Infomation, MobileModal, ViewPager } from '../../component'
import { useAppDispatch, useAppState, useAppTranslation, useWindowDimensions } from '../../hook'
import { Link } from 'react-router-dom'
import { FoundCard } from './found-card'
import { ProfileCard } from './profile-card'
import { Menu } from '../../enum'
import { ManagementModal } from '../company/constants'
import { PortfolioManagement } from './constants/portfolio-managment'
import './managers.page.scss'

interface ManagersProps {}

export const Managers = (props: ManagersProps) => {
    const dispatch = useAppDispatch()
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()

    const [state, setState] = useAppState({
        modalVisible: false,
        managerId: 0,
    })

    const handleModalVisible = (id: number) => {
        setState((prevState) => ({ ...prevState, modalVisible: !state.modalVisible }))
        if (id) {
            setState((prevState) => ({ ...prevState, managerId: id }))
        }

        document.body.style.overflow = ''
    }

    const getTopProfileCards = () => {
        var cards: React.ReactElement[] = []

        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_halas_zoltan_title')} position={t('managers_halas_zoltan_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(1)} id={0} />)
        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_czako_agnes_title')} position={t('managers_czako_agnes_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(2)} id={1} />)

        return cards
    }

    const getBottomProfileCards = () => {
        var cards: React.ReactElement[] = []

        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_gajdacs_attila_title')} position={t('managers_gajdacs_attila_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(3)} id={2} />)
        cards.push(<ProfileCard title={t('managers_analyzis_title')} name={t('managers_heinz_frigyes_title')} position={t('managers_heinz_frigyes_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(4)} id={3} />)

        return cards
    }

    const getAllProfileCards = () => {
        var cards: React.ReactElement[] = []

        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_halas_zoltan_title')} position={t('managers_halas_zoltan_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(1)} id={0} />)
        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_czako_agnes_title')} position={t('managers_czako_agnes_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(2)} id={1} />)
        cards.push(<ProfileCard title={t('managers_managers_title')} name={t('managers_gajdacs_attila_title')} position={t('managers_gajdacs_attila_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(3)} id={2} />)
        cards.push(<ProfileCard title={t('managers_analyzis_title')} name={t('managers_heinz_frigyes_title')} position={t('managers_heinz_frigyes_position_title')} buttonTitle={t('management_card_introduction_button_title')} setModalVisible={(e) => handleModalVisible(4)} id={3} />)

        return cards
    }

    const getFoundCards = () => {
        var cards: React.ReactElement[] = []

        cards.push(<FoundCard key={0} title={t('managers_found_otp_climate_title')} buttonTitle={t('managers_details_button')} url={'https://www.otpbank.hu/otpalapkezelo/hu/A_Klimavaltozas'} />)
        cards.push(<FoundCard key={1} title={t('managers_found_otp_trend_title')} buttonTitle={t('managers_details_button')} url={'https://www.otpbank.hu/otpalapkezelo/hu/A_Trend'} />)
        cards.push(<FoundCard key={2} title={t('managers_found_otp_omega_title')} buttonTitle={t('managers_details_button')} url={'https://www.otpbank.hu/otpalapkezelo/hu/A_Omega'} />)
        cards.push(<FoundCard key={3} title={t('managers_found_otp_absolute_title')} buttonTitle={t('managers_details_button')} url={'https://www.otpbank.hu/otpalapkezelo/hu/A_AbszolutHozam'} />)
        cards.push(<FoundCard key={4} title={t('managers_found_otp_absolute_euro_title')} buttonTitle={t('managers_details_button')} url={'https://www.otpbank.hu/otpalapkezelo/hu/A_AbszolutHozamEuro'} />)

        return cards
    }

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    return (
        <main className="page page-managers">
            <div className="main-div">
                <div className="top-container">
                    {/* <div className="leader-container">
                        <div className="profile-image" />

                        <div className="content-container">
                            <h1 className="title-text">{t('managers_czachesz_gabor_title')}</h1>
                            <p className="sub-title-text">{t('managers_czachesz_gabor_position_title')}</p>
                            <span className="description-text">{t('managers_czachesz_gabor_description')}</span>

                            <div className="bottom-content-container">
                                <div className="badge-container">
                                    <div className="badge">
                                        <img className="icon" src="image/otp_managers_badge_left.svg" />

                                        <h3 className="title-text-h3">{t('managers_bage_left_title')}</h3>
                                    </div>
                                    <div className="badge">
                                        <img className="icon" src="image/otp_managers_badge_center.svg" />

                                        <h3 className="title-text-h3">{t('managers_bage_center_title')}</h3>
                                    </div>
                                    <div className="badge">
                                        <img className="icon" src="image/otp_managers_badge_right.svg" />

                                        <h3 className="title-text-h3">{t('managers_bage_right_title')}</h3>
                                    </div>
                                </div>
                                <span className="source-text">{t('managers_czachesz_gabor_source_title')}</span>
                            </div>
                        </div>
                    </div> */}

                    <div className="coworker-container">
                        {/* <h1 className="title-text">{t('managers_coworker_title')}</h1> */}

                        <div className="coworker-profile-container">{width <= 576 ? <ViewPager cards={getAllProfileCards()} /> : <>{getTopProfileCards().map((card, index) => card)}</>}</div>

                        <div className="coworker-profile-container top-margin">{width <= 576 ? null : <>{getBottomProfileCards().map((card, index) => card)}</>}</div>
                    </div>
                </div>

                <div className="middle-container">
                    <div className={`middle-container-bg ${window?.visualViewport!!.width < width ? `` : `full-width`}`} />

                    <div className="middle-container-inner">
                        <h2 className="title-text-h2">{t('managers_czachesz_gabor_founds_title')}</h2>

                        <div className="founds-container">{width <= 852 ? <ViewPager cards={getFoundCards()} /> : <>{getFoundCards().map((card, index) => card)}</>}</div>
                    </div>
                </div>

                <div className="bottom-container">
                    <div className="afterword">
                        <h3>{t('founds_page_landing_page_afterwords')}</h3>
                    </div>

                    <Link
                        className="middle-container-button-link"
                        to={'/alapok'}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0 })

                            onMenuItemClick(Menu.Founds)
                        }}
                    >
                        <button className="button">{t('header_menu_founds_button')}</button>
                    </Link>
                </div>

                {width <= 576 ? (
                    <>
                        <FooterCard />
                        <Infomation />
                    </>
                ) : (
                    <>
                        <Infomation />
                        <FooterCard />
                    </>
                )}

                {state.modalVisible && width > 576 ? (
                    <ManagementModal url={PortfolioManagement[state.managerId - 1].imageUrl} title={t(PortfolioManagement[state.managerId - 1].name)} description={t(PortfolioManagement[state.managerId - 1].description)} postDescription={t(PortfolioManagement[state.managerId - 1].postDesctription)} onCloseClick={() => handleModalVisible(NaN)} />
                ) : (
                    state.modalVisible && width <= 576 && <MobileModal visible={state.modalVisible} title={t(PortfolioManagement[state.managerId - 1].name)} description={t(PortfolioManagement[state.managerId - 1].description)} postDescription={t(PortfolioManagement[state.managerId - 1].postDesctription)} hideButton={true} onCloseClick={() => handleModalVisible(NaN)} />
                )}
            </div>
        </main>
    )
}
