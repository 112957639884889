import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../hook'
import { RootState } from '../../../store'
import { createFormattedDateString, numberWithSpaces } from '../../../helper/date.helper'
import './exchange-menu.scss'

interface ExchangeMenuProps {
    open: boolean
}

export const ExchangeMenu = (props: ExchangeMenuProps) => {
    const { t } = useTranslation()

    const exchangeRates = useAppSelector((state: RootState) => state.exchangeRate.exchangeRates)

    return (
        <div className={`exchange-menu ${props.open ? `` : `hidden`}`}>
            <img alt="" className="bubble" src="/image/ic_bubble_indicator.svg" />

            <div className="exchange-menu-container">
                <div className="item">
                    <div className="top" style={{ backgroundColor: 'var(--color-conservative-green)' }}>
                        <p className="title">{t('founds_page_landing_page_first_card_header_title')}</p>
                    </div>
                    <div className="bottom">
                        <div className="container">
                            <p className="sub-title">{t('header_founds_date_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? createFormattedDateString(exchangeRates[0].date) : ''}</p>
                        </div>

                        <div className="container" style={{ backgroundColor: 'white' }}>
                            <p className="sub-title">{t('header_founds_exchange_rate_title')}</p>

                            <p className="sub-title">{exchangeRates != null ? exchangeRates[0].exchangeRate : ''} EUR</p>
                        </div>

                        <div className="container">
                            <p className="sub-title">{t('header_founds_net_price_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? numberWithSpaces(exchangeRates[0].netPrice) : ''} EUR</p>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="top" style={{ backgroundColor: 'var(--color-orange)' }}>
                        <p className="title">{t('founds_page_landing_page_second_card_header_title')}</p>
                    </div>
                    <div className="bottom">
                        <div className="container">
                            <p className="sub-title">{t('header_founds_date_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? createFormattedDateString(exchangeRates[1].date) : ''}</p>
                        </div>

                        <div className="container" style={{ backgroundColor: 'white' }}>
                            <p className="sub-title">{t('header_founds_exchange_rate_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? exchangeRates[1].exchangeRate : ''} EUR</p>
                        </div>

                        <div className="container">
                            <p className="sub-title">{t('header_founds_net_price_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? numberWithSpaces(exchangeRates[1].netPrice) : ''} EUR</p>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="top" style={{ backgroundColor: 'var(--color-red)' }}>
                        <p className="title">{t('founds_page_landing_page_third_card_header_title')}</p>
                    </div>
                    <div className="bottom">
                        <div className="container">
                            <p className="sub-title">{t('header_founds_date_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? createFormattedDateString(exchangeRates[2].date) : ''}</p>
                        </div>

                        <div className="container" style={{ backgroundColor: 'white' }}>
                            <p className="sub-title">{t('header_founds_exchange_rate_title')}</p>

                            <p className="sub-title">{exchangeRates != null ? exchangeRates[2].exchangeRate : ''} EUR</p>
                        </div>

                        <div className="container">
                            <p className="sub-title">{t('header_founds_net_price_title')}</p>
                            <p className="sub-title">{exchangeRates != null ? numberWithSpaces(exchangeRates[2].netPrice) : ''} EUR</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
