import { useEffect } from 'react'
import { useAppNavigate } from '../../hook'
import './mobile.modal.scss'

type MobileModalProps = {
    visible: boolean
    title: string
    description: string
    path?: string
    buttonText?: string
    onCloseClick: () => void
    hideButton?: boolean
    postDescription?: string
}

export const MobileModal = ({ title, description, path, buttonText, visible, onCloseClick, hideButton, postDescription }: MobileModalProps) => {
    const navigate = useAppNavigate()

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
    }, [visible])

    const onInfoButtonClick = () => {
        window.scrollTo({ top: 0, left: 0 })
        navigate(path!)
        //onInfoButtonClick()
    }

    return (
        <div className={`modal-container ${visible ? `` : `hidden`}`}>
            <div className="header-container">
                <header className="modal-title">
                    <span>{title}</span>
                </header>
                <button
                    className="modal-close-button"
                    onClick={() => {
                        onCloseClick()
                    }}
                >
                    <img src="/image/ic_close.svg" alt="Bezárás gomb" />
                </button>
            </div>
            <div className="descriptionn-container">
                <span>{description} </span>
                {postDescription && <span>{postDescription}</span>}
                <button className={`button ${hideButton ? 'hidden' : ''}`} onClick={onInfoButtonClick}>
                    {buttonText}
                </button>
            </div>
        </div>
    )
}
